@font-face {
  font-family: 'rawline';
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg") format("svg");
  font-weight: 100;
  font-style: normal; }

* {
  margin: 0;
  padding: 0; }

body {
  min-width: 320px;
  font-family: "Rubik", sans-serif; }
  body.overfl {
    overflow: hidden;
    height: 100vh; }

.start-page {
  padding: 30px; }
  .start-page-logo {
    display: block;
    margin-bottom: 30px; }
  .start-page ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px; }
    .start-page ul li {
      padding: 5px 0; }
      .start-page ul li b {
        font-size: 20px; }
      .start-page ul li a {
        font-size: 16px;
        line-height: 140%;
        color: #000000; }
        .start-page ul li a .red {
          color: red; }

div:focus,
span:focus,
a:focus,
input:focus,
textarea:focus,
select:focus,
button:focus,
.button:focus {
  outline: none; }

input,
textarea {
  box-shadow: none !important; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

a {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

svg use {
  transition: 0.3s; }

.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 1760px) {
    .container {
      max-width: 1730px; } }

.button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s; }
  .button-primary {
    padding: 15px 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    background: #00A1E1;
    border-radius: 6px; }
    @media screen and (max-width: 991px) {
      .button-primary {
        font-size: 14px;
        line-height: 140%; } }
    .button-primary:hover {
      color: #ffffff;
      background: #048BC1; }

h1 {
  margin-bottom: 35px;
  font-size: 56px;
  line-height: 130%;
  font-weight: 700;
  color: #343642; }
  .banner h1 {
    font-size: 64px;
    line-height: 130%;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-weight: 500; }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 45px !important;
      line-height: 50px !important; } }
  @media screen and (max-width: 991px) {
    h1 {
      margin-bottom: 25px;
      font-size: 32px !important;
      line-height: 130% !important; } }
  @media screen and (max-width: 359px) {
    h1 {
      font-size: 28px !important;
      line-height: 130% !important; } }

h2 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 56px;
  line-height: 117%;
  color: #343642; }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 38px;
      line-height: 44px; } }
  @media screen and (max-width: 991px) {
    h2 {
      margin-bottom: 25px;
      font-size: 32px;
      line-height: 115%; } }
  h2 .bl {
    color: #26AEE5; }
  h2 .rs {
    color: #EC038C; }
  h2 .ye {
    color: #FFE100; }

.header {
  position: relative; }
  @media screen and (max-width: 991px) {
    .header.open:before {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      top: 89px;
      left: 0;
      z-index: 10;
      background: #E5E6E9; } }
  .header__inner {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .header__right {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .header__right {
        display: none;
        width: 100%;
        height: auto;
        padding: 120px 20px 30px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 8;
        background: #ffffff; }
        .open .header__right {
          display: block; } }
  .header__toggler {
    display: none; }
    .header__toggler svg {
      width: 24px;
      height: 16px; }
      .header__toggler svg use {
        color: #00A1E1; }
        .banner .header__toggler svg use {
          color: #ffffff; }
    @media screen and (max-width: 991px) {
      .header__toggler {
        display: block;
        position: relative;
        z-index: 9; } }
    @media screen and (max-width: 991px) {
      .header__toggler {
        width: 24px;
        height: 20px; }
        .header__toggler:before {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background-image: url("../images/svg/close.svg");
          opacity: 0;
          background-size: 51px 51px;
          background-position: center; }
        .open .header__toggler svg {
          display: none; }
        .open .header__toggler:before {
          opacity: 1; } }
  .header__menu ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    transition: 0.3s; }
    @media screen and (max-width: 1350px) {
      .header__menu ul li {
        margin: 0 2px; } }
    @media screen and (max-width: 991px) {
      .header__menu ul li {
        display: block;
        margin-bottom: 24px; } }
    .header__menu ul li a {
      display: inline-block;
      padding: 10px 18px;
      font-size: 20px;
      line-height: 24px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.3s;
      color: #343642; }
      @media screen and (max-width: 1580px) {
        .header__menu ul li a {
          font-size: 18px;
          line-height: 22px; } }
      @media screen and (max-width: 1470px) {
        .header__menu ul li a {
          font-size: 16px;
          line-height: 20px; } }
      @media screen and (max-width: 1350px) {
        .header__menu ul li a {
          padding: 10px; } }
      @media screen and (max-width: 1200px) {
        .header__menu ul li a {
          font-size: 14px;
          line-height: 18px; } }
      @media screen and (max-width: 1050px) {
        .header__menu ul li a {
          padding: 8px; } }
      @media screen and (max-width: 991px) {
        .header__menu ul li a {
          color: #707378 !important;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          padding: 0; } }
      .banner .header__menu ul li a {
        color: #ffffff; }
    @media screen and (min-width: 991px) {
      .header__menu ul li:hover {
        background: #CCEFFC; }
        .banner_on .header__menu ul li:hover {
          background: #3375AC; }
        .banner_th .header__menu ul li:hover {
          background: rgba(255, 255, 255, 0.2); }
      .header__menu ul li.active a {
        background: #CCEFFC; }
        .banner_on .header__menu ul li.active a {
          background: #3375AC; }
        .banner_th .header__menu ul li.active a {
          background: rgba(255, 255, 255, 0.2); } }
  .header__info {
    margin-left: 108px; }
    @media screen and (max-width: 1660px) {
      .header__info {
        margin-left: 50px; } }
    @media screen and (max-width: 1580px) {
      .header__info {
        margin-left: 20px; } }
    @media screen and (max-width: 1050px) {
      .header__info {
        margin-left: 10px; } }
    @media screen and (max-width: 991px) {
      .header__info {
        margin-left: 0;
        margin-top: 59px; } }
  .header__address {
    display: none; }
    @media screen and (max-width: 991px) {
      .header__address {
        display: block;
        color: #707378;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%; } }
  .header__tel {
    position: relative;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #00A1E1; }
    @media screen and (max-width: 1470px) {
      .header__tel {
        font-size: 18px;
        line-height: 22px; } }
    @media screen and (max-width: 1350px) {
      .header__tel {
        font-size: 16px;
        line-height: 20px; } }
    @media screen and (max-width: 1200px) {
      .header__tel {
        font-size: 14px;
        line-height: 18px; } }
    @media screen and (max-width: 991px) {
      .header__tel {
        margin-bottom: 12px;
        display: inline-block;
        color: #343642 !important;
        font-size: 18px;
        line-height: 21px; } }
    .banner .header__tel {
      color: #ffffff; }
    .header__tel:after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      z-index: 2;
      transition: 0.3s;
      opacity: 0;
      background: #00A1E1; }
      .banner .header__tel:after {
        background: #ffffff; }
    .header__tel:hover {
      color: #00A1E1; }
      .banner .header__tel:hover {
        color: #ffffff; }
      .header__tel:hover:after {
        opacity: 1; }
  .header__btn {
    padding: 13px 40px;
    display: inline-block;
    margin-left: 29px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    border: 2px solid transparent;
    border-radius: 6px;
    color: #00A1E1;
    border-color: #00A1E1; }
    .header__btn:hover {
      background: #00A1E1;
      color: #ffffff; }
    @media screen and (max-width: 1350px) {
      .header__btn {
        margin-left: 15px;
        padding: 13px 20px;
        font-size: 14px;
        line-height: 18px; } }
    @media screen and (max-width: 1050px) {
      .header__btn {
        padding: 13px;
        margin-left: 10px;
        font-size: 12px;
        line-height: 16px; } }
    @media screen and (max-width: 991px) {
      .header__btn {
        display: none; } }
    .banner .header__btn {
      color: #ffffff;
      border-color: #ffffff; }
      .banner .header__btn:hover {
        color: #343642;
        background: #ffffff; }
  @media screen and (max-width: 991px) {
    .header__logo {
      position: relative;
      z-index: 9; } }
  .header__logo .drop {
    display: none; }
    @media screen and (max-width: 991px) {
      .open .header__logo .drop {
        display: block; } }
  @media screen and (max-width: 991px) {
    .open .header__logo .desc {
      display: none; } }
  @media screen and (max-width: 1500px) {
    .header__logo img {
      max-width: 200px; } }
  @media screen and (max-width: 1200px) {
    .header__logo img {
      max-width: 150px; } }

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden; }
  .banner__inner {
    position: relative; }
  .banner__logo {
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 2; }
    @media screen and (max-width: 991px) {
      .banner__logo {
        top: 20px; }
        .banner__logo img {
          max-width: 148px; } }
  .banner__holder {
    display: flex;
    align-items: center;
    padding: 80px 0 100px;
    position: relative; }
    @media screen and (max-width: 991px) {
      .banner__holder {
        display: block;
        padding: 30px 0; } }
  .banner__text {
    max-width: 720px; }
    @media screen and (max-width: 1200px) {
      .banner__text {
        max-width: 650px; } }
    @media screen and (max-width: 991px) {
      .banner__text {
        max-width: 100%; }
        .banner_tw .banner__text {
          padding-top: 95px; } }
    .banner_tw .banner__text {
      padding-top: 105px; }
  .banner__img {
    width: calc(100% - 720px); }
    @media screen and (max-width: 1200px) {
      .banner__img {
        width: calc(100% - 650px); } }
    @media screen and (max-width: 991px) {
      .banner__img {
        width: 100%;
        margin-top: 50px; } }
    .banner__img img {
      display: block;
      margin-left: auto;
      margin-right: 0; }
      @media screen and (max-width: 1760px) {
        .banner__img img {
          max-width: 100%; } }
  .banner__tx {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    letter-spacing: 0.01em; }
    @media screen and (max-width: 991px) {
      .banner__tx {
        font-size: 16px;
        line-height: 150%;
        font-weight: 400; }
        .banner__tx br {
          display: none; } }
  .banner__form {
    margin-top: 45px; }
    @media screen and (max-width: 991px) {
      .banner__form {
        margin-top: 37px; } }
    .banner__form-inner {
      display: flex;
      align-items: center; }
      .banner__form-inner input {
        max-width: 265px;
        width: 100%;
        margin-right: 10px;
        height: 52px;
        padding: 15px 20px;
        border-radius: 6px;
        background: #ffffff;
        color: #343642;
        font-size: 16px;
        line-height: 19px;
        border: none;
        box-sizing: border-box; }
        .banner__form-inner input::-webkit-input-placeholder {
          color: #343642; }
        .banner__form-inner input:-ms-input-placeholder {
          color: #343642; }
        .banner__form-inner input::placeholder {
          color: #343642; }
        @media screen and (max-width: 991px) {
          .banner__form-inner input {
            height: 44px;
            max-width: 100%;
            width: calc(100% - 44px);
            padding: 5px 20px; } }
      .banner__form-inner .button {
        height: 52px;
        padding: 15px 53px;
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
        color: #ffffff;
        background: #00A1E1;
        border-radius: 6px; }
        @media screen and (max-width: 991px) {
          .banner__form-inner .button {
            height: 44px;
            width: 44px;
            padding: 5px 20px;
            background-image: url("../images/svg/phone.svg");
            background-repeat: no-repeat;
            background-position: center;
            color: transparent !important; } }
        .banner__form-inner .button:hover {
          background-color: #048BC1; }
    .banner__form-tx {
      margin-top: 12px;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff; }
      @media screen and (max-width: 991px) {
        .banner__form-tx {
          margin-top: 5px;
          font-size: 12px;
          line-height: 140%; } }
      .banner__form-tx a {
        color: #00A1E1; }
        .banner__form-tx a:hover {
          color: #048BC1; }

.descr {
  margin: 120px 0; }
  @media screen and (max-width: 991px) {
    .descr {
      margin: 60px 0; }
      .descr_right .col-lg-6:first-of-type {
        order: 2; }
      .descr_right .col-lg-6:last-of-type {
        order: 1; } }
  .descr .row {
    align-items: center; }
  @media screen and (max-width: 991px) {
    .descr__img {
      margin-bottom: 25px; } }
  .descr__img img {
    max-width: 100%;
    margin: auto;
    display: block; }
  .descr__text p {
    font-size: 20px;
    line-height: 150%;
    color: #707378; }
    @media screen and (max-width: 991px) {
      .descr__text p {
        font-size: 16px;
        line-height: 150%; }
        .descr__text p br {
          display: none; } }
  .descr__text-title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #333333;
    text-transform: uppercase; }
    @media screen and (max-width: 991px) {
      .descr__text-title {
        margin-bottom: 11px;
        font-size: 14px;
        line-height: 150%; } }
  .descr__text-img {
    margin-bottom: 40px; }
    @media screen and (max-width: 991px) {
      .descr__text-img {
        margin-bottom: 15px; } }
    .descr__text-img img {
      margin-right: 35px;
      display: inline-block;
      vertical-align: middle;
      max-width: 100%; }
      @media screen and (max-width: 1200px) {
        .descr__text-img img {
          margin-right: 10px; } }
      @media screen and (max-width: 991px) {
        .descr__text-img img {
          max-height: 15px;
          margin-right: 11px; } }
  .descr__text .button {
    margin-top: 35px; }
    @media screen and (max-width: 991px) {
      .descr__text .button {
        margin-top: 30px;
        display: flex;
        width: 100%; } }

.slick-dots {
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 2;
  text-align: center; }
  .slick-dots li {
    margin: 0 2px;
    width: 7px;
    height: 7px;
    display: inline-block;
    vertical-align: middle;
    background: #DEDCDC;
    border-radius: 100px; }
    .slick-dots li:before, .slick-dots li:after,
    .slick-dots li button {
      display: none; }
    .slick-dots li.slick-active {
      background: #00A1E1; }

@media screen and (max-width: 991px) {
  .av {
    background: #F6F7F9; } }

.av h2 {
  margin-bottom: 65px; }
  @media screen and (max-width: 991px) {
    .av h2 {
      margin-bottom: 33px; } }

.av .row {
  margin-bottom: -50px;
  position: relative; }
  @media screen and (max-width: 991px) {
    .av .row {
      flex-wrap: inherit; } }

@media screen and (max-width: 991px) {
  .av .slick-slide {
    width: 310px !important; } }

@media screen and (max-width: 991px) {
  .av .col-lg-4 {
    padding: 0 15px; } }

.av__inner {
  padding: 90px 117px;
  border-radius: 32px;
  background: #F6F7F9; }
  @media screen and (max-width: 1300px) {
    .av__inner {
      padding: 50px 60px; } }
  @media screen and (max-width: 991px) {
    .av__inner {
      padding: 40px 0 60px; } }

.av__item {
  margin-bottom: 50px;
  display: flex; }
  @media screen and (max-width: 991px) {
    .av__item {
      display: block; } }
  .av__item-icon {
    width: 52px; }
    @media screen and (max-width: 991px) {
      .av__item-icon {
        width: 100%; } }
  .av__item-tx {
    width: calc(100% - 52px);
    padding-left: 25px; }
    @media screen and (max-width: 991px) {
      .av__item-tx {
        width: 100%;
        padding-left: 0;
        margin-top: 12px; } }
    .av__item-tx-title {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: #333333;
      letter-spacing: 0.047em; }
      @media screen and (max-width: 991px) {
        .av__item-tx-title {
          font-size: 20px;
          line-height: 150%; } }
    .av__item-tx-tt {
      font-size: 18px;
      line-height: 150%;
      color: #90949A; }
      @media screen and (max-width: 991px) {
        .av__item-tx-tt {
          font-size: 16px;
          line-height: 150%; }
          .av__item-tx-tt br {
            display: none; } }

.form {
  margin: 120px 0; }
  @media screen and (max-width: 991px) {
    .form {
      margin: 70px 0;
      background: #F6F7F9; } }
  .form__inner {
    display: flex;
    align-items: flex-start;
    padding: 90px 90px 65px;
    position: relative;
    background: #F6F7F9;
    border-radius: 32px; }
    @media screen and (max-width: 1400px) {
      .form__inner {
        padding: 50px; } }
    @media screen and (max-width: 991px) {
      .form__inner {
        display: block;
        padding: 40px 0; } }
  .form__text {
    width: 50%;
    padding-right: 20px; }
    @media screen and (max-width: 991px) {
      .form__text {
        width: 100%;
        padding-right: 0; } }
    .form__text-tx {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: #707378; }
      @media screen and (max-width: 1200px) {
        .form__text-tx br {
          display: none; } }
      @media screen and (max-width: 991px) {
        .form__text-tx {
          font-size: 16px;
          line-height: 150%; } }
  .form__info {
    width: 40%;
    position: absolute;
    bottom: 80px;
    left: 90px; }
    @media screen and (max-width: 1400px) {
      .form__info {
        left: 50px; } }
    @media screen and (max-width: 991px) {
      .form__info {
        width: 100%;
        margin-top: 95px;
        position: relative;
        bottom: 0;
        left: 0; } }
  .form__tel {
    margin-bottom: 15px;
    display: inline-block;
    font-size: 28px;
    line-height: 33px;
    color: #00A1E1; }
    @media screen and (max-width: 991px) {
      .form__tel {
        font-size: 22px;
        line-height: 26px; } }
    .form__tel:hover {
      color: #3375AC; }
  .form__address {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #707378; }
    @media screen and (max-width: 991px) {
      .form__address {
        font-size: 14px;
        line-height: 160%; } }
  .form__cell {
    width: 50%;
    padding: 0 8px; }
    @media screen and (max-width: 1200px) {
      .form__cell {
        width: 100%;
        padding: 0; } }
    .form__cell-tw {
      padding-left: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #93ABB8; }
      @media screen and (max-width: 1200px) {
        .form__cell-tw {
          padding-left: 0;
          margin-top: 5px; } }
      .form__cell-tw a {
        color: #00A1E1; }
        .form__cell-tw a:hover {
          color: #3375AC; }
    .form__cell.w-100 {
      width: 100%; }
    .form__cell .button {
      width: 100%;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase; }
      @media screen and (max-width: 991px) {
        .form__cell .button {
          padding: 15px; } }
    .form__cell input {
      width: 100%;
      height: 58px;
      padding: 10px 20px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #343642;
      background: #F6F7F9;
      border-radius: 6px;
      border: 1px solid #F6F7F9; }
      .form__cell input::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell input:-ms-input-placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell input:focus {
        border-color: #00A1E1;
        background: #ffffff; }
      @media screen and (max-width: 991px) {
        .form__cell input {
          height: 52px;
          margin-bottom: 12px; } }
    .form__cell textarea {
      height: 114px;
      width: 100%;
      padding: 17px 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #343642;
      background: #F6F7F9;
      border-radius: 6px;
      border: 1px solid #F6F7F9; }
      .form__cell textarea::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell textarea:-ms-input-placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell textarea::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #A9AEB2; }
      .form__cell textarea:focus {
        border-color: #00A1E1;
        background: #ffffff; }
      @media screen and (max-width: 991px) {
        .form__cell textarea {
          height: 138px;
          margin-bottom: 12px; } }
  .form__row {
    display: flex;
    align-items: center;
    margin: 0 -8px; }
    @media screen and (max-width: 1200px) {
      .form__row {
        display: block;
        margin: 0; } }
    .form__row-bottom {
      margin-top: 26px; }
      @media screen and (max-width: 991px) {
        .form__row-bottom {
          margin-top: 5px; } }
  .form__form {
    width: 50%;
    padding: 53px;
    background: #ffffff;
    border-radius: 10px; }
    .contacts__info .form__form {
      padding: 44px; }
    @media screen and (max-width: 991px) {
      .form__form {
        width: 100%;
        padding: 24px 16px 40px;
        margin-top: 46px; } }
    .form__form-title {
      margin-bottom: 13px;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: #343642; }
      @media screen and (max-width: 991px) {
        .form__form-title {
          font-size: 18px;
          line-height: 130%; } }
    .form__form-inner + .form__form-inner {
      margin-top: 28px; }
      @media screen and (max-width: 991px) {
        .form__form-inner + .form__form-inner {
          margin-top: 32px; } }
    .form__form .open .dropdown-menu {
      display: block;
      top: 110%; }
    .form__form .filter-option-inner-inner {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: #65676E; }
    .form__form .dropdown-toggle {
      width: 100%;
      height: 58px;
      padding: 10px 49px 10px 20px;
      background: #F6F7F9;
      border-radius: 6px;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #65676E; }
      @media screen and (max-width: 991px) {
        .form__form .dropdown-toggle {
          height: 52px; } }
      .form__form .dropdown-toggle:after {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 0;
        right: 13px;
        bottom: 0;
        margin: auto;
        z-index: 2;
        background-image: url("../images/svg/arrow.svg");
        background-repeat: no-repeat;
        border: none !important; }
    .form__form .dropdown-item {
      padding: 0; }
    .form__form .multiselect-selected-text {
      border: none;
      box-shadow: none !important;
      display: block;
      text-align: left;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden; }
    .form__form .multiselect-option {
      margin-bottom: 10px; }
      .form__form .multiselect-option:last-of-type {
        margin-bottom: 0; }
    .form__form .multiselect-native-select {
      display: block;
      width: 100%; }
      @media screen and (max-width: 1200px) {
        .form__form .multiselect-native-select {
          margin-bottom: 16px; } }
      @media screen and (max-width: 991px) {
        .form__form .multiselect-native-select {
          margin-bottom: 12px; } }
      .form__form .multiselect-native-select .btn-group {
        display: block;
        width: 100%; }
    .form__form .multiselect-option {
      background-color: transparent !important; }
      .form__form .multiselect-option.active {
        background-color: transparent !important; }
      .form__form .multiselect-option:hover {
        background-color: transparent !important; }
      .form__form .multiselect-option input[type="checkbox"] {
        display: none; }
        .form__form .multiselect-option input[type="checkbox"] + label {
          min-height: 20px;
          display: flex;
          align-items: center;
          padding-left: 30px;
          width: 100%;
          position: relative;
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          color: #65676E;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre-wrap; }
          .form__form .multiselect-option input[type="checkbox"] + label:before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            border: 1px solid #90949A;
            border-radius: 3px; }
        .form__form .multiselect-option input[type="checkbox"]:checked + label:before {
          background-color: #00A1E1;
          background-image: url("../images/svg/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          border-color: #00A1E1;
          background-size: inherit; }
    .form__form .dropdown-menu {
      width: 100%;
      padding: 20px 0;
      border: 1px solid #00A1E1;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      background: #ffffff; }

.footer {
  background: #37383A; }
  .footer__top {
    padding: 40px 0 63px; }
    @media screen and (max-width: 1450px) {
      .footer__top {
        padding-bottom: 40px; } }
    @media screen and (max-width: 991px) {
      .footer__top {
        padding: 30px 0; } }
    .footer__top-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media screen and (max-width: 991px) {
        .footer__top-inner {
          display: block; } }
  .footer__bottom {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0; }
    @media screen and (max-width: 991px) {
      .footer__bottom {
        padding: 35px 0; } }
    .footer__bottom-inner {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media screen and (max-width: 991px) {
        .footer__bottom-inner {
          display: block; } }
  .footer__logo {
    display: inline-block; }
    @media screen and (max-width: 1450px) {
      .footer__logo img {
        max-width: 150px; } }
    @media screen and (max-width: 991px) {
      .footer__logo img {
        max-width: inherit; } }
  @media screen and (max-width: 991px) {
    .footer__menu {
      margin-top: 33px; } }
  .footer__menu ul li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 35px; }
    @media screen and (max-width: 1570px) {
      .footer__menu ul li {
        margin: 0 10px; } }
    @media screen and (max-width: 991px) {
      .footer__menu ul li {
        display: block;
        margin: 14px 0; } }
    .footer__menu ul li a {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff; }
      @media screen and (max-width: 1300px) {
        .footer__menu ul li a {
          font-size: 13px;
          line-height: 18px; } }
      @media screen and (max-width: 991px) {
        .footer__menu ul li a {
          font-size: 16px;
          line-height: 19px; } }
      .footer__menu ul li a:hover {
        color: #3375AC; }
  .footer__info {
    display: flex;
    align-items: flex-start;
    padding-right: 50px;
    padding-left: 75px; }
    @media screen and (max-width: 1650px) {
      .footer__info {
        padding-right: 0;
        padding-left: 50px; } }
    @media screen and (max-width: 1450px) {
      .footer__info {
        padding-left: 15px;
        width: 50%; } }
    @media screen and (max-width: 1200px) {
      .footer__info {
        width: 30%;
        display: block;
        text-align: right; } }
    @media screen and (max-width: 991px) {
      .footer__info {
        width: 100%;
        padding-left: 0;
        margin-top: 40px;
        text-align: left; } }
    .footer__info-cell {
      display: inline-block;
      vertical-align: top; }
    .footer__info-item:first-of-type {
      margin-right: 77px; }
      @media screen and (max-width: 1450px) {
        .footer__info-item:first-of-type {
          margin-right: 20px; } }
      @media screen and (max-width: 1200px) {
        .footer__info-item:first-of-type {
          margin-right: 0;
          margin-bottom: 15px; } }
      @media screen and (max-width: 991px) {
        .footer__info-item:first-of-type {
          margin-bottom: 30px; } }
  .footer__tel {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff; }
    @media screen and (max-width: 1300px) {
      .footer__tel {
        font-size: 13px;
        line-height: 18px; } }
    @media screen and (max-width: 991px) {
      .footer__tel {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 150%;
        color: #00A1E1; } }
    .footer__tel:hover {
      color: #00A1E1; }
  .footer__address {
    font-size: 16px;
    line-height: 19px;
    color: #E5E6E9; }
    @media screen and (max-width: 1300px) {
      .footer__address {
        font-size: 13px;
        line-height: 18px; } }
    @media screen and (max-width: 991px) {
      .footer__address {
        font-size: 16px;
        line-height: 19px;
        color: #A9AEB2; } }
  .footer__tls {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #A9AEB2; }
    @media screen and (max-width: 1300px) {
      .footer__tls {
        font-size: 13px;
        line-height: 18px; } }
    @media screen and (max-width: 991px) {
      .footer__tls {
        font-size: 16px;
        line-height: 150%; } }
    .footer__tls:last-of-type {
      margin-bottom: 0; }
    .footer__tls a {
      display: inline-block;
      margin-right: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #A9AEB2; }
      @media screen and (max-width: 991px) {
        .footer__tls a {
          color: #00A1E1; } }
      .footer__tls a:hover {
        color: #00A1E1; }
  .footer__tx {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #65676E; }
    @media screen and (max-width: 991px) {
      .footer__tx {
        margin-bottom: 17px; } }
  .footer__lk {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #65676E; }
    @media screen and (max-width: 991px) {
      .footer__lk {
        display: table;
        margin-bottom: 17px; } }
    .footer__lk:hover {
      color: #00A1E1; }
  .footer__dev {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #65676E; }
    .footer__dev svg {
      width: 22px;
      height: 22px; }
      .footer__dev svg use {
        color: #65676E; }
    .footer__dev:hover {
      color: #00A1E1; }
      .footer__dev:hover svg use {
        color: #00A1E1; }

.contacts {
  margin-bottom: 120px; }
  @media screen and (max-width: 991px) {
    .contacts {
      margin-bottom: 0; } }
  .contacts__tb {
    border-radius: 32px 32px 0px 0px;
    background: #F6F7F9; }
    @media screen and (max-width: 991px) {
      .contacts__tb {
        margin: 0 -20px;
        border-radius: 0; } }
    .contacts__tb .nav-tabs {
      padding: 0 90px;
      border-bottom: 1px solid #DFDFDF; }
      @media screen and (max-width: 1600px) {
        .contacts__tb .nav-tabs {
          padding: 0 30px; } }
      @media screen and (max-width: 991px) {
        .contacts__tb .nav-tabs {
          padding: 0 20px; } }
    .contacts__tb .nav-item {
      margin-right: 60px; }
      @media screen and (max-width: 991px) {
        .contacts__tb .nav-item {
          margin-right: 45px; } }
    .contacts__tb .nav-link {
      padding: 24px 0;
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #90949A;
      border: none;
      background: transparent !important;
      /*&:before {
          content: '';
          width: 100px;
          height: 2px;
          display: block;

          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 2;

          background: $blue2;
          opacity: 0;
        }*/ }
      @media screen and (max-width: 991px) {
        .contacts__tb .nav-link {
          padding: 20px 0;
          font-size: 16px;
          line-height: 19px; } }
      .contacts__tb .nav-link:hover {
        color: #00A1E1; }
      .contacts__tb .nav-link.active {
        margin-bottom: -1px;
        color: #343642;
        border-bottom: 2px solid #00A1E1; }
        .contacts__tb .nav-link.active:before {
          opacity: 1; }
  .contacts__info {
    display: flex; }
    @media screen and (max-width: 991px) {
      .contacts__info {
        display: block;
        margin-bottom: 40px; } }
    .contacts__info .form__form {
      width: 100%; }
      @media screen and (max-width: 991px) {
        .contacts__info .form__form {
          margin-top: 0;
          padding: 23px 20px; } }
    .contacts__info-cell {
      width: 48%;
      border-right: 1px solid #DFDFDF; }
      @media screen and (max-width: 991px) {
        .contacts__info-cell {
          display: block;
          width: 100%; } }
    .contacts__info-form {
      width: 52%;
      padding: 70px 90px; }
      @media screen and (max-width: 1600px) {
        .contacts__info-form {
          padding: 70px 30px; } }
      @media screen and (max-width: 991px) {
        .contacts__info-form {
          display: block;
          width: 100%;
          padding: 0 20px; } }
  .contacts__call {
    padding: 70px 50px 70px 90px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DFDFDF; }
    @media screen and (max-width: 1600px) {
      .contacts__call {
        padding: 70px 30px; } }
    @media screen and (max-width: 1400px) {
      .contacts__call {
        display: block; } }
    @media screen and (max-width: 991px) {
      .contacts__call {
        padding: 40px 20px;
        display: flex;
        flex-wrap: wrap; } }
    .contacts__call-img {
      width: 78px;
      height: 78px;
      overflow: hidden;
      border-radius: 100px; }
      @media screen and (max-width: 1400px) {
        .contacts__call-img {
          display: inline-block;
          vertical-align: middle; } }
      @media screen and (max-width: 991px) {
        .contacts__call-img {
          width: 68px; }
          .contacts__call-img img {
            width: 68px; } }
    .contacts__call-in {
      width: calc(100% - 300px);
      padding: 0 45px 0 33px; }
      @media screen and (max-width: 1400px) {
        .contacts__call-in {
          width: auto;
          display: inline-block;
          vertical-align: middle; } }
      @media screen and (max-width: 991px) {
        .contacts__call-in {
          width: calc(100% - 68px);
          padding: 0 0 0 26px; } }
    .contacts__call-position {
      font-size: 20px;
      line-height: 150%;
      color: #707378; }
      @media screen and (max-width: 991px) {
        .contacts__call-position {
          font-size: 16px;
          line-height: 150%; } }
    .contacts__call-tel {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: #343642; }
      @media screen and (max-width: 991px) {
        .contacts__call-tel {
          font-size: 20px;
          line-height: 24px; } }
      .contacts__call-tel:hover {
        color: #00A1E1; }
    @media screen and (max-width: 1400px) {
      .contacts__call-btn {
        margin-top: 20px; } }
    @media screen and (max-width: 991px) {
      .contacts__call-btn {
        margin-top: 10px;
        width: 100%; }
        .contacts__call-btn .button {
          display: flex;
          width: 100%; } }
    .contacts__call-btn .button {
      padding: 15px 30px;
      text-transform: uppercase; }
  .contacts__list {
    padding: 67px 90px 50px; }
    @media screen and (max-width: 1600px) {
      .contacts__list {
        padding: 70px 30px; } }
    @media screen and (max-width: 991px) {
      .contacts__list {
        padding: 38px 20px; } }
    .contacts__list-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px; }
      @media screen and (max-width: 991px) {
        .contacts__list-row {
          display: block; } }
    .contacts__list-cell {
      width: 48%;
      padding: 0 10px;
      margin-bottom: 37px; }
      @media screen and (max-width: 991px) {
        .contacts__list-cell {
          width: 100%;
          margin-bottom: 25px; }
          .contacts__list-cell:last-of-type {
            margin-bottom: 0; } }
    .contacts__list-title {
      display: flex;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: #343642; }
      @media screen and (max-width: 991px) {
        .contacts__list-title {
          font-size: 18px;
          line-height: 130%; } }
      .contacts__list-title img {
        width: 24px; }
      .contacts__list-title span {
        width: calc(100% - 24px);
        padding-left: 10px; }
    .contacts__list-tx {
      margin-top: 12px;
      margin-left: 33px;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: #707378; }
      @media screen and (max-width: 991px) {
        .contacts__list-tx {
          margin-top: 8px;
          font-size: 16px;
          line-height: 160%; } }
    .contacts__list-link {
      display: table;
      margin-left: 33px;
      margin-top: 12px;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: #707378; }
      @media screen and (max-width: 991px) {
        .contacts__list-link {
          margin-top: 3px;
          font-size: 16px;
          line-height: 160%; } }
      .contacts__list-link:hover {
        color: #00A1E1; }

.map {
  width: 100%;
  height: 540px;
  position: relative; }
  @media screen and (max-width: 991px) {
    .map {
      height: 418px; } }
  .map #map1,
  .map #map2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }

.modal-backdrop {
  background: #343642;
  opacity: 0.6; }

.modal-dialog {
  max-width: 640px; }

.modal-body {
  padding: 60px 40px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .modal-body {
      padding: 45px 20px; } }
  .modal-body .button {
    margin: 40px auto 0;
    max-width: 282px;
    display: flex;
    text-transform: uppercase; }

.modal-content {
  border-radius: 10px;
  border: none; }
  .modal-content .btn-close {
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    opacity: 1;
    background: none !important; }
    @media screen and (max-width: 768px) {
      .modal-content .btn-close {
        width: 24px;
        height: 24px;
        top: 15px;
        right: 15px; }
        .modal-content .btn-close img {
          max-width: 100%; } }

.modal-title {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  color: #343642; }
  @media screen and (max-width: 768px) {
    .modal-title {
      font-size: 24px;
      line-height: 120%; } }

.modal-tx {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  color: #707378; }
  @media screen and (max-width: 768px) {
    .modal-tx {
      font-size: 16px;
      line-height: 150%; }
      .modal-tx br {
        display: none; } }
  .modal-tx b {
    color: #343642; }
