$white: #ffffff;
$black: #000000;
$black2: #343642;
$black3: #333333;
$gray: #707378;
$gray2: #90949A;
$gray3: #65676E;
$gray4: #A9AEB2;
$gray5: #93ABB8;
$gray6: #37383A;
$gray7: #E5E6E9;
$gray8: #DEDCDC;
$gray9: #DFDFDF;

$blue: #3375AC;
$blue2: #00A1E1;
$blue3: #048BC1;
$blue4: #26AEE5;
$blue5: #CCEFFC;

$bg: #F6F7F9;

$rose: #EC038C;
$ye: #FFE100;

$font-family: 'Rubik', sans-serif;
