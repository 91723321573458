@import "variables.scss";

@font-face {
  font-family: 'rawline';
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot');
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2') format('woff2'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff') format('woff'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf') format('truetype'),
  url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
}


body {
  min-width: 320px;

  font-family: $font-family;

  &.overfl {
    overflow: hidden;
    height: 100vh;
  }
}

.start-page {
  padding: 30px;

  &-logo {
    display: block;
    margin-bottom: 30px;
  }

  ul {
    display: inline-block;
    vertical-align: top;
    padding-right: 60px;

    li {
      padding: 5px 0;

      b {
        font-size: 20px;
      }

      a {
        font-size: 16px;
        line-height: 140%;
        color: $black;

        &:hover {
          //color: $active;
        }

        .red {
          color: red;
        }
      }
    }
  }
}

div,
span,
a,
input,
textarea,
select,
button,
.button {
  &:focus {
    outline: none;
  }
}

input,
textarea {
  box-shadow: none!important;
}


ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

svg {
  use {
    transition: 0.3s;
  }
}

.container {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and(min-width: 1760px) {
    max-width: 1730px;
  }
}


.button {
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  transition: 0.3s;

  &-primary {
    padding: 15px 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $white;
    background: $blue2;
    border-radius: 6px;


    @media screen and(max-width: 991px) {
      font-size: 14px;
      line-height: 140%;
    }

    &:hover {
      color: $white;
      background: $blue3;
    }
  }
}

h1 {
  margin-bottom: 35px;

  font-size: 56px;
  line-height: 130%;
  font-weight: 700;

  color: $black2;

  .banner & {
    font-size: 64px;
    line-height: 130%;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-weight: 500;
  }

  @media screen and(max-width: 1200px) {
    font-size: 45px!important;
    line-height: 50px!important;
  }

  @media screen and(max-width: 991px) {
    margin-bottom: 25px;

    font-size: 32px!important;
    line-height: 130%!important;
  }

  @media screen and(max-width: 359px) {
    font-size: 28px!important;
    line-height: 130%!important;
  }
}

h2 {
  margin-bottom: 30px;

  font-weight: 600;
  font-size: 56px;
  line-height: 117%;
  color: $black2;

  @media screen and(max-width: 1200px) {
    font-size: 38px;
    line-height: 44px;
  }

  @media screen and(max-width: 991px) {
    margin-bottom: 25px;

    font-size: 32px;
    line-height: 115%;
  }

  .bl {
    color: $blue4;
  }

  .rs {
    color: $rose;
  }

  .ye {
    color: $ye;
  }
}

.header {
  position: relative;

  @media screen and(max-width: 991px) {
    &.open {
      &:before {
        content: '';
        width: 100%;
        height: 1px;
        display: block;

        position: absolute;
        top: 89px;
        left: 0;
        z-index: 10;

        background: $gray7;
      }
    }
  }

  &__inner {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and(max-width: 991px) {

    }
  }

  &__right {
    display: flex;
    align-items: center;

    @media screen and(max-width: 991px) {
      display: none;
      width: 100%;
      height: auto;
      padding: 120px 20px 30px;

      position: fixed;
      top: 0;
      left: 0;
      z-index: 8;

      background: $white;

      .open & {
        display: block;
      }
    }
  }

  &__toggler {
    display: none;

    svg {
      width: 24px;
      height: 16px;

      use {
        color: $blue2;

        .banner & {
          color: $white;
        }
      }
    }

    @media screen and(max-width: 991px) {
      display: block;

      position: relative;
      z-index: 9;
    }

    @media screen and(max-width: 991px) {
      width: 24px;
      height: 20px;

      &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        background-image: url("../images/svg/close.svg");
        opacity: 0;
        background-size: 51px 51px;
        background-position: center;
      }

      .open & {
        svg {
          display: none;
        }

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &__menu {
    ul {
      li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;

        line-height: 0;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.3s;

        @media screen and(max-width: 1350px) {
          margin: 0 2px;
        }

        @media screen and(max-width: 991px) {
          display: block;
          margin-bottom: 24px;
        }

        a {
          display: inline-block;
          padding: 10px 18px;

          font-size: 20px;
          line-height: 24px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.3s;
          color: $black2;

          @media screen and(max-width: 1580px) {
            font-size: 18px;
            line-height: 22px;
          }

          @media screen and(max-width: 1470px) {
            font-size: 16px;
            line-height: 20px;
          }

          @media screen and(max-width: 1350px) {
            padding: 10px;
          }

          @media screen and(max-width: 1200px) {
            font-size: 14px;
            line-height: 18px;
          }

          @media screen and(max-width: 1050px) {
            padding: 8px;
          }

          @media screen and(max-width: 991px) {
            color: $gray!important;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            padding: 0;
          }

          .banner & {
            color: $white;
          }
        }

        @media screen and(min-width: 991px) {
          &:hover {
            background: $blue5;

            .banner_on & {
              background: $blue;
            }

            .banner_th & {
              background: rgba(255, 255, 255, 0.2);
            }
          }

          &.active {
            a {
              background: $blue5;


              .banner_on & {
                background: $blue;
              }

              .banner_th & {
                background: rgba(255, 255, 255, 0.2);
              }
            }
          }
        }
      }
    }
  }

  &__info {
    margin-left: 108px;

    @media screen and(max-width: 1660px) {
      margin-left: 50px;
    }

    @media screen and(max-width: 1580px) {
      margin-left: 20px;
    }

    @media screen and(max-width: 1050px) {
      margin-left: 10px;
    }

    @media screen and(max-width: 991px) {
      margin-left: 0;
      margin-top: 59px;
    }
  }

  &__address {
    display: none;

    @media screen and(max-width: 991px) {
      display: block;

      color: $gray;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }
  }

  &__tel {
    position: relative;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $blue2;

    @media screen and(max-width: 1470px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and(max-width: 1350px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and(max-width: 1200px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media screen and(max-width: 991px) {
      margin-bottom: 12px;
      display: inline-block;

      color: $black2!important;
      font-size: 18px;
      line-height: 21px;
    }

    .banner & {
      color: $white;
    }

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      display: block;

      position: absolute;
      bottom: -3px;
      left: 0;
      z-index: 2;

      transition: 0.3s;
      opacity: 0;
      background: $blue2;

      .banner & {
        background: $white;
      }
    }

    &:hover {
      color: $blue2;

      .banner & {
        color: $white;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &__btn {
    padding: 13px 40px;
    display: inline-block;
    margin-left: 29px;

    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;

    border: 2px solid transparent;
    border-radius: 6px;

    color: $blue2;
    border-color: $blue2;

    &:hover {
      background: $blue2;
      color: $white;
    }

    @media screen and(max-width: 1350px) {
      margin-left: 15px;

      padding: 13px 20px;
      font-size: 14px;
      line-height: 18px;
    }

    @media screen and(max-width: 1050px) {
      padding: 13px;
      margin-left: 10px;

      font-size: 12px;
      line-height: 16px;
    }

    @media screen and(max-width: 991px) {
      display: none;
    }

    .banner & {
      color: $white;
      border-color: $white;

      &:hover {
        color: $black2;
        background: $white;
      }
    }
  }

  &__logo {
    @media screen and(max-width: 991px) {
      position: relative;
      z-index: 9;
    }



    .drop {
      display: none;

      @media screen and(max-width: 991px) {
        .open & {
          display: block;
        }
      }
    }

    .desc {
      @media screen and(max-width: 991px) {
        .open & {
          display: none;
        }
      }
    }

    img {
      @media screen and(max-width: 1500px) {
        max-width: 200px;
      }

      @media screen and(max-width: 1200px) {
        max-width: 150px;
      }
    }
  }
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  overflow: hidden;

  &__inner {
    position: relative;
  }

  &__logo {
    position: absolute;
    top: 37px;
    left: 0;
    z-index: 2;

    @media screen and(max-width: 991px) {
      top: 20px;

      img {
        max-width: 148px;
      }
    }
  }

  &__holder {
    display: flex;
    align-items: center;
    padding: 80px 0 100px;

    position: relative;

    @media screen and(max-width: 991px) {
      display: block;
      padding: 30px 0;
    }
  }

  &__text {
    max-width: 720px;

    @media screen and(max-width: 1200px) {
      max-width: 650px;
    }

    @media screen and(max-width: 991px) {
      max-width: 100%;

      .banner_tw & {
        padding-top: 95px;
      }
    }

    .banner_tw & {
      padding-top: 105px;
    }
  }

  &__img {
    width: calc(100% - 720px);

    @media screen and(max-width: 1200px) {
      width: calc(100% - 650px);
    }

    @media screen and(max-width: 991px) {
      width: 100%;
      margin-top: 50px;
    }


    img {
      display: block;
      margin-left: auto;
      margin-right: 0;

      @media screen and(max-width: 1760px) {
        max-width: 100%;
      }
    }
  }

  &__tx {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: $white;
    letter-spacing: 0.01em;

    @media screen and(max-width: 991px) {
      font-size: 16px;
      line-height: 150%;
      font-weight: 400;

      br {
        display: none;
      }
    }
  }

  &__form {
    margin-top: 45px;

    @media screen and(max-width: 991px) {
      margin-top: 37px;
    }

    &-inner {
      display: flex;
      align-items: center;

      input {
        max-width: 265px;
        width: 100%;
        margin-right: 10px;
        height: 52px;
        padding: 15px 20px;

        border-radius: 6px;
        background: $white;
        color: $black2;
        font-size: 16px;
        line-height: 19px;
        border: none;
        box-sizing: border-box;

        &::-webkit-input-placeholder {  color: $black2; }
        &:-ms-input-placeholder { color: $black2; }
        &::placeholder {  color: $black2; }

        @media screen and(max-width: 991px) {
          height: 44px;
          max-width: 100%;
          width: calc(100% - 44px);
          padding: 5px 20px;
        }
      }

      .button {
        height: 52px;
        padding: 15px 53px;
        display: inline-block;

        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
        color: $white;
        background: $blue2;
        border-radius: 6px;

        @media screen and(max-width: 991px) {
          height: 44px;
          width: 44px;
          padding: 5px 20px;

          background-image: url("../images/svg/phone.svg");
          background-repeat: no-repeat;
          background-position: center;
          color: transparent!important;
        }

        &:hover {
          background-color: $blue3;
        }
      }
    }

    &-tx {
      margin-top: 12px;

      font-size: 14px;
      line-height: 140%;
      color: $white;

      @media screen and(max-width: 991px) {
        margin-top: 5px;

        font-size: 12px;
        line-height: 140%;
      }

      a {
        color: $blue2;

        &:hover {
          color: $blue3;
        }
      }
    }
  }
}

.descr {
  margin: 120px 0;

  @media screen and(max-width: 991px) {
    margin: 60px 0;

    &_right {
      .col-lg-6 {
        &:first-of-type {
          order: 2;
        }

        &:last-of-type {
          order: 1;
        }
      }
    }
  }

  .row {
    align-items: center;
  }

  &__img {
    @media screen and(max-width: 991px) {
      margin-bottom: 25px;
    }

    img {
      max-width: 100%;
      margin: auto;
      display: block;
    }
  }

  &__text {
    p {
      font-size: 20px;
      line-height: 150%;
      color: $gray;

      @media screen and(max-width: 991px) {
        font-size: 16px;
        line-height: 150%;

        br {
          display: none;
        }
      }
    }

    &-title {
      margin-bottom: 20px;

      font-weight: 500;
      font-size: 18px;
      line-height: 150%;
      color: $black3;
      text-transform: uppercase;

      @media screen and(max-width: 991px) {
        margin-bottom: 11px;

        font-size: 14px;
        line-height: 150%;
      }
    }

    &-img {
      margin-bottom: 40px;

      @media screen and(max-width: 991px) {
        margin-bottom: 15px;
      }

      img {
        margin-right: 35px;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;

        @media screen and(max-width: 1200px) {
          margin-right: 10px;
        }

        @media screen and(max-width: 991px) {
          max-height: 15px;
          margin-right: 11px;
        }
      }
    }

    .button {
      margin-top: 35px;

      @media screen and(max-width: 991px) {
        margin-top: 30px;
        display: flex;
        width: 100%;
      }
    }
  }
}

.slick {
  &-dots {
    width: 100%;

    position: absolute;
    bottom: 5px;
    left: 0;
    z-index: 2;

    text-align: center;

    li {
      margin: 0 2px;
      width: 7px;
      height: 7px;
      display: inline-block;
      vertical-align: middle;

      background: $gray8;
      border-radius: 100px;

      &:before,
      &:after,
      button {
        display: none;
      }

      &.slick-active {
        background: $blue2;
      }
    }
  }
}

.av {
  @media screen and(max-width: 991px) {
    background: $bg;
  }

  h2 {
    margin-bottom: 65px;

    @media screen and(max-width: 991px) {
      margin-bottom: 33px;
    }
  }

  .row {
    margin-bottom: -50px;

    position: relative;

    @media screen and(max-width: 991px) {
      flex-wrap: inherit;
    }
  }

  .slick-slide {
    @media screen and(max-width: 991px) {
      width: 310px!important;
    }
  }

  .col-lg-4 {
    @media screen and(max-width: 991px) {
      padding: 0 15px;
    }
  }

  &__inner {
    padding: 90px 117px;

    border-radius: 32px;
    background: $bg;

    @media screen and(max-width: 1300px) {
      padding: 50px 60px;
    }

    @media screen and(max-width: 991px) {
      padding: 40px 0 60px;
    }
  }

  &__item {
    margin-bottom: 50px;
    display: flex;

    @media screen and(max-width: 991px) {
      display: block;
    }

    &-icon {
      width: 52px;

      @media screen and(max-width: 991px) {
        width: 100%;
      }
    }

    &-tx {
      width: calc(100% - 52px);
      padding-left: 25px;


      @media screen and(max-width: 991px) {
        width: 100%;
        padding-left: 0;
        margin-top: 12px;
      }

      &-title {
        margin-bottom: 12px;

        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: $black3;
        letter-spacing: 0.047em;

        @media screen and(max-width: 991px) {
          font-size: 20px;
          line-height: 150%;
        }
      }

      &-tt {
        font-size: 18px;
        line-height: 150%;
        color: $gray2;

        @media screen and(max-width: 991px) {
          font-size: 16px;
          line-height: 150%;

          br {
            display: none;
          }
        }
      }
    }
  }
}

.form {
  margin: 120px 0;

  @media screen and(max-width: 991px) {
    margin: 70px 0;
    background: $bg;
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    padding: 90px 90px 65px;

    position: relative;

    background: $bg;
    border-radius: 32px;

    @media screen and(max-width: 1400px) {
      padding: 50px;
    }

    @media screen and(max-width: 991px) {
      display: block;
      padding: 40px 0;
    }
  }

  &__text {
    width: 50%;
    padding-right: 20px;

    @media screen and(max-width: 991px) {
      width: 100%;
      padding-right: 0;
    }

    &-tx {
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: $gray;

      @media screen and(max-width: 1200px) {
        br {
          display: none;
        }
      }

      @media screen and(max-width: 991px) {
        font-size: 16px;
        line-height: 150%;
      }
    }
  }

  &__info {
    width: 40%;

    position: absolute;
    bottom: 80px;
    left: 90px;

    @media screen and(max-width: 1400px) {
      left: 50px;
    }

    @media screen and(max-width: 991px) {
      width: 100%;
      margin-top: 95px;

      position: relative;
      bottom: 0;
      left: 0;
    }
  }

  &__tel {
    margin-bottom: 15px;
    display: inline-block;
    font-size: 28px;
    line-height: 33px;
    color: $blue2;

    @media screen and(max-width: 991px) {
      font-size: 22px;
      line-height: 26px;
    }

    &:hover {
      color: $blue;
    }
  }

  &__address {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: $gray;

    @media screen and(max-width: 991px) {
      font-size: 14px;
      line-height: 160%;
    }
  }

  &__cell {
    width: 50%;
    padding: 0 8px;

    @media screen and(max-width: 1200px) {
      width: 100%;
      padding: 0;
    }

    &-tw {
      padding-left: 10px;

      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: $gray5;

      @media screen and(max-width: 1200px) {
        padding-left: 0;
        margin-top: 5px;
      }

      a {
        color: $blue2;

        &:hover {
          color: $blue;
        }
      }
    }

    &.w-100 {
      width: 100%;
    }

    .button {
      width: 100%;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      text-transform: uppercase;

      @media screen and(max-width: 991px) {
        padding: 15px;
      }
    }

    input {
      width: 100%;
      height: 58px;
      padding: 10px 20px;
      margin-bottom: 16px;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $black2;
      background: $bg;
      border-radius: 6px;
      border: 1px solid $bg;


      &::-webkit-input-placeholder {  font-weight: 400; font-size: 14px; color: $gray4; }
      &:-ms-input-placeholder { font-weight: 400; font-size: 14px; color: $gray4; }
      &::placeholder {  font-weight: 400; font-size: 14px; color: $gray4; }

      &:focus {
        border-color: $blue2;
        background: $white;
      }

      @media screen and(max-width: 991px) {
        height: 52px;
        margin-bottom: 12px;
      }
    }

    textarea {
      height: 114px;
      width: 100%;
      padding: 17px 20px;

      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $black2;
      background: $bg;
      border-radius: 6px;
      border: 1px solid $bg;


      &::-webkit-input-placeholder {  font-weight: 400; font-size: 14px; color: $gray4; }
      &:-ms-input-placeholder { font-weight: 400; font-size: 14px; color: $gray4; }
      &::placeholder {  font-weight: 400; font-size: 14px; color: $gray4; }

      &:focus {
        border-color: $blue2;
        background: $white;
      }

      @media screen and(max-width: 991px) {
        height: 138px;
        margin-bottom: 12px;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    @media screen and(max-width: 1200px) {
      display: block;
      margin: 0;
    }

    &-bottom {
      margin-top: 26px;

      @media screen and(max-width: 991px) {
        margin-top: 5px;
      }
    }
  }

  &__form {
    width: 50%;

    padding: 53px;

    background: $white;
    border-radius: 10px;

    .contacts__info & {
      padding: 44px;
    }

    @media screen and(max-width: 991px) {
      width: 100%;
      padding: 24px 16px 40px;
      margin-top: 46px;
    }

    &-title {
      margin-bottom: 13px;

      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: $black2;

      @media screen and(max-width: 991px) {
        font-size: 18px;
        line-height: 130%;
      }
    }

    &-inner {
      & + .form__form-inner {
        margin-top: 28px;

        @media screen and(max-width: 991px) {
          margin-top: 32px;
        }
      }
    }

    .open {
      .dropdown-menu {
        display: block;
        top: 110%;
      }
    }

    .filter-option-inner-inner {
      font-weight: 600;
      font-size: 14px;
      line-height: 130%;
      color: $gray3;
    }

    .btn-group {

    }

    .dropdown {
      &-toggle {
        width: 100%;
        height: 58px;
        padding: 10px 49px 10px 20px;

        background: $bg;
        border-radius: 6px;

        border: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $gray3;

        @media screen and(max-width: 991px) {
          height: 52px;
        }


        &:after {
          content: '';
          width: 16px;
          height: 16px;
          display: block;

          position: absolute;
          top: 0;
          right: 13px;
          bottom: 0;
          margin: auto;
          z-index: 2;

          background-image: url("../images/svg/arrow.svg");
          background-repeat: no-repeat;

          border: none!important;
        }
      }

      &-item {
        padding: 0;

        .form-check {

        }
      }
    }

    .multiselect {
      &-selected {
        &-text {
          border: none;
          box-shadow: none!important;
          display: block;
          text-align: left;

          display: block;
          text-overflow: ellipsis;
          overflow: hidden;

        }
      }

      &-option {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-native {
        &-select {
          display: block;
          width: 100%;

          @media screen and(max-width: 1200px) {
            margin-bottom: 16px;
          }

          @media screen and(max-width: 991px) {
            margin-bottom: 12px;
          }

          .btn-group {
            display: block;
            width: 100%;
          }
        }
      }

      &-option  {
        background-color: transparent!important;

        &.active {
          background-color: transparent!important;
        }

        &:hover {
          background-color: transparent!important;
        }

        .dropdown {
          &-toggle {

          }

          &-item {
          }
        }

        input {
          &[type="checkbox"] {
            display: none;

            & + label {
              min-height: 20px;
              display: flex;
              align-items: center;
              padding-left: 30px;
              width: 100%;

              position: relative;

              font-weight: 600;
              font-size: 14px;
              line-height: 130%;
              color: $gray3;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: pre-wrap;

              &:before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;

                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                border: 1px solid $gray2;
                border-radius: 3px;
              }
            }

            &:checked {
              & + label {
                &:before {
                  background-color: $blue2;
                  background-image: url("../images/svg/check.svg");
                  background-repeat: no-repeat;
                  background-position: center;
                  border-color: $blue2;
                  background-size: inherit;
                }
              }
            }
          }
        }
      }
    }

    .dropdown-menu {
      width: 100%;
      padding: 20px 0;

      border: 1px solid $blue2;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      background: $white;
    }
  }
}

.footer {
  background: $gray6;

  &__inner {

  }

  &__top {
    padding: 40px 0 63px;

    @media screen and(max-width: 1450px) {
      padding-bottom: 40px;
    }

    @media screen and(max-width: 991px) {
      padding: 30px 0;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and(max-width: 991px) {
        display: block;
      }
    }
  }

  &__bottom {
    border: 1px solid rgba(255,255,255,0.1);
    padding: 15px 0;

    @media screen and(max-width: 991px) {
      padding: 35px 0;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and(max-width: 991px) {
        display: block;
      }
    }
  }

  &__logo {
    display: inline-block;

    @media screen and(max-width: 1450px) {
      img {
        max-width: 150px;
      }
    }

    @media screen and(max-width: 991px) {
      img {
        max-width: inherit;
      }
    }
  }

  &__menu {
    @media screen and(max-width: 991px) {
      margin-top: 33px;
    }

    ul {
      li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 35px;

        @media screen and(max-width: 1570px) {
          margin: 0 10px;
        }

        @media screen and(max-width: 991px) {
          display: block;
          margin: 14px 0;
        }

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $white;

          @media screen and(max-width: 1300px) {
            font-size: 13px;
            line-height: 18px;
          }

          @media screen and(max-width: 991px) {
            font-size: 16px;
            line-height: 19px;
          }

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    align-items: flex-start;
    padding-right: 50px;
    padding-left: 75px;

    @media screen and(max-width: 1650px) {
      padding-right: 0;
      padding-left: 50px;
    }

    @media screen and(max-width: 1450px) {
      padding-left: 15px;
      width: 50%;
    }

    @media screen and(max-width: 1200px) {
      width: 30%;
      display: block;

      text-align: right;
    }

    @media screen and(max-width: 991px) {
      width: 100%;
      padding-left: 0;
      margin-top: 40px;

      text-align: left;
    }

    &-cell {
      display: inline-block;
      vertical-align: top;
    }

    &-item {
      &:first-of-type {
        margin-right: 77px;

        @media screen and(max-width: 1450px) {
          margin-right: 20px;
        }

        @media screen and(max-width: 1200px) {
          margin-right: 0;
          margin-bottom: 15px;
        }

        @media screen and(max-width: 991px) {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__tel {
    display: inline-block;
    margin-bottom: 4px;

    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: $white;

    @media screen and(max-width: 1300px) {
      font-size: 13px;
      line-height: 18px;
    }

    @media screen and(max-width: 991px) {
      margin-bottom: 10px;

      font-size: 18px;
      line-height: 150%;
      color: $blue2;
    }

    &:hover {
      color: $blue2;
    }
  }

  &__address {
    font-size: 16px;
    line-height: 19px;
    color: $gray7;

    @media screen and(max-width: 1300px) {
      font-size: 13px;
      line-height: 18px;
    }

    @media screen and(max-width: 991px) {
      font-size: 16px;
      line-height: 19px;
      color: $gray4;
    }
  }

  &__tls {
    margin-bottom: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $gray4;

    @media screen and(max-width: 1300px) {
      font-size: 13px;
      line-height: 18px;
    }

    @media screen and(max-width: 991px) {
      font-size: 16px;
      line-height: 150%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      display: inline-block;
      margin-right: 5px;

      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: $gray4;

      @media screen and(max-width: 991px) {
        color: $blue2;
      }

      &:hover {
        color: $blue2;
      }
    }
  }

  &__tx {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray3;

    @media screen and(max-width: 991px) {
      margin-bottom: 17px;
    }
  }

  &__lk {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray3;

    @media screen and(max-width: 991px) {
      display: table;
      margin-bottom: 17px;
    }

    &:hover {
      color: $blue2;
    }
  }

  &__dev {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $gray3;

    svg {
      width: 22px;
      height: 22px;

      use {
        color: $gray3;
      }
    }

    &:hover {
      color: $blue2;

      svg {
        use {
          color: $blue2;
        }
      }
    }
  }
}

.contacts {
  margin-bottom: 120px;

  @media screen and(max-width: 991px) {
    margin-bottom: 0;
  }

 &__inner {

 }

  &__tb {
    border-radius: 32px 32px 0px 0px;
    background: $bg;

    @media screen and(max-width: 991px) {
      margin: 0 -20px;
      border-radius: 0;
    }

    .nav {
      &-tabs {
        padding: 0 90px;

        border-bottom: 1px solid $gray9;

        @media screen and(max-width: 1600px) {
          padding: 0 30px;
        }

        @media screen and(max-width: 991px) {
          padding: 0 20px;
        }
      }

      &-item {
        margin-right: 60px;

        @media screen and(max-width: 991px) {
          margin-right: 45px;
        }
      }

      &-link {
        padding: 24px 0;
        display: inline-block;
        margin-bottom: 0;

        position: relative;

        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $gray2;
        border: none;

        background: transparent!important;

        @media screen and(max-width: 991px) {
          padding: 20px 0;

          font-size: 16px;
          line-height: 19px;
        }

        /*&:before {
          content: '';
          width: 100px;
          height: 2px;
          display: block;

          position: absolute;
          bottom: -1px;
          left: 0;
          z-index: 2;

          background: $blue2;
          opacity: 0;
        }*/

        &:hover {
          color: $blue2;
        }

        &.active {
          margin-bottom: -1px;
          color: $black2;

          border-bottom: 2px solid $blue2;

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__info {
    display: flex;

    @media screen and(max-width: 991px) {
      display: block;
      margin-bottom: 40px;
    }

    .form__form {
      width: 100%;

      @media screen and(max-width: 991px) {
        margin-top: 0;
        padding: 23px 20px;
      }
    }

    &-cell {
      width: 48%;

      border-right: 1px solid $gray9;

      @media screen and(max-width: 991px) {
        display: block;
        width: 100%;
      }
    }

    &-form {
      width: 52%;

      padding: 70px 90px;

      @media screen and(max-width: 1600px) {
        padding: 70px 30px;
      }

      @media screen and(max-width: 991px) {
        display: block;
        width: 100%;
        padding: 0 20px;
      }
    }
  }

  &__call {
    padding: 70px 50px 70px 90px;
    display: flex;
    align-items: center;

    border-bottom: 1px solid $gray9;

    @media screen and(max-width: 1600px) {
      padding: 70px 30px;
    }

    @media screen and(max-width: 1400px) {
      display: block;
    }

    @media screen and(max-width: 991px) {
      padding: 40px 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &-img {
      width: 78px;
      height: 78px;

      overflow: hidden;
      border-radius: 100px;

      @media screen and(max-width: 1400px) {
        display: inline-block;
        vertical-align: middle;
      }

      @media screen and(max-width: 991px) {
        width: 68px;

        img {
          width: 68px;
        }
      }
    }

    &-in {
      width: calc(100% - 300px);
      padding: 0 45px 0 33px;

      @media screen and(max-width: 1400px) {
        width: auto;
        display: inline-block;
        vertical-align: middle;
      }

      @media screen and(max-width: 991px) {
        width: calc(100% - 68px);
        padding: 0 0 0 26px;
      }
    }

    &-position {
      font-size: 20px;
      line-height: 150%;
      color: $gray;

      @media screen and(max-width: 991px) {
        font-size: 16px;
        line-height: 150%;
      }
    }

    &-tel {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $black2;

      @media screen and(max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
      }

      &:hover {
        color: $blue2;
      }
    }

    &-btn {
      @media screen and(max-width: 1400px) {
        margin-top: 20px;
      }

      @media screen and(max-width: 991px) {
        margin-top: 10px;
        width: 100%;

        .button {
          display: flex;
          width: 100%;
        }
      }

      .button {
        padding: 15px 30px;

        text-transform: uppercase;
      }
    }
  }

  &__list {
    padding: 67px 90px 50px;

    @media screen and(max-width: 1600px) {
      padding: 70px 30px;
    }

    @media screen and(max-width: 991px) {
      padding: 38px 20px;
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @media screen and(max-width: 991px) {
        display: block;
      }
    }

    &-cell {
      width: 48%;
      padding: 0 10px;
      margin-bottom: 37px;

      @media screen and(max-width: 991px) {
        width: 100%;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &-title {
      display: flex;

      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      color: $black2;

      @media screen and(max-width: 991px) {
        font-size: 18px;
        line-height: 130%;
      }

      img {
        width: 24px;
      }

      span {
        width: calc(100% - 24px);
        padding-left: 10px;
      }
    }

    &-tx {
      margin-top: 12px;
      margin-left: 33px;

      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: $gray;

      @media screen and(max-width: 991px) {
        margin-top: 8px;

        font-size: 16px;
        line-height: 160%;
      }
    }

    &-link {
      display: table;
      margin-left: 33px;
      margin-top: 12px;

      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: $gray;

      @media screen and(max-width: 991px) {
        margin-top: 3px;

        font-size: 16px;
        line-height: 160%;
      }

      &:hover {
        color: $blue2;
      }
    }
  }
}

.map {
  width: 100%;
  height: 540px;

  position: relative;

  @media screen and(max-width: 991px) {
    height: 418px;
  }

  #map1,
  #map2 {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.modal {
  &-backdrop {
    background: $black2;
    opacity: 0.6;
  }
  &-dialog {
    max-width: 640px;
  }

  &-body {
    padding: 60px 40px;

    text-align: center;

    @media screen and(max-width: 768px) {
      padding: 45px 20px;
    }

    .button {
      margin: 40px auto 0;
      max-width: 282px;
      display: flex;

      text-transform: uppercase;
    }
  }

  &-content {
    border-radius: 10px;
    border: none;

    .btn-close {
      width: 32px;
      height: 32px;
      display: block;

      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;

      opacity: 1;

      background: none!important;

      @media screen and(max-width: 768px) {
        width: 24px;
        height: 24px;

        top: 15px;
        right: 15px;

        img {
          max-width: 100%;
        }
      }
    }
  }


  &-title {
    margin-bottom: 25px;

    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: $black2;

    @media screen and(max-width: 768px) {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &-tx {
    margin-bottom: 10px;

    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    color: $gray;

    @media screen and(max-width: 768px) {
      font-size: 16px;
      line-height: 150%;

      br {
        display: none;
      }
    }

    b {
      color: $black2;
    }
  }
}
















